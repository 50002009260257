import axios from 'axios';
import { loadPersistentState } from '../utils/persist';

const API_URL = 'https://api.boxo.trade/v1';

export function api() {
  return axios.create({
    baseURL: API_URL,
  });
}

export function auth_api() {
  const config = {
    baseURL: API_URL,
  };
  const session = loadPersistentState('session');
  if (!session) return { api: api() };
  const { token, refreshToken } = session;
  if (token) {
    config.headers = { Authorization: `Bearer ${token}` };
  }
  return { api: axios.create(config), refreshToken };
}

export function api_middleware() {
  return axios.create({});
}

export function auth_api_middleware() {
  const config = {};
  const session = loadPersistentState('session');
  if (!session) return { api: api_middleware() };
  const { token, refreshToken } = session;
  if (token) {
    config.headers = { Authorization: `${token}` };
  }
  return { api: axios.create(config), refreshToken };
}

import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useLoggerContext } from '../shared/hooks/use-logger';
import { isAndroid, isIos, isNativeApp, isTelegram } from '../shared/utils';

export default function Download() {
  const { isReady, event } = useLoggerContext();
  const [params] = useSearchParams();
  const src = params.get('src');
  useEffect(() => {
    event('download', { src });
  }, []);

  useEffect(() => {
    if (!isReady) return;
    if (isNativeApp() || isTelegram()) window.location.href = '/';
    else if (isAndroid())
      window.location.href =
        'https://play.google.com/store/apps/details?id=com.BoxoTradeApp';
    else if (isIos())
      window.location.href =
        'https://apps.apple.com/us/app/crypto-game-boxo/id6725853622';
    else window.location.href = '/';
  }, [isReady]);

  return <></>;
}

import { t } from 'i18next';
import { useEffect } from 'react';
import { ModalLayout } from '../../shared/layout';
import { useLocalStorage } from '../../shared/hooks/use-hooks';
import { SelectionList } from '../../components/terminal';
import { persistUserProfile } from '../../shared/api/profile';
import { SystemDialog } from '../../shared/dialogs';
import { useState } from 'react';
import { useSubscribe } from '../../shared/hooks/use-push-notifications';
import {
  isAndroid,
  isIos,
  isNativeApp,
  isPwa,
  isTelegram,
} from '../../shared/utils';
import { useRef } from 'react';
import { useLocation } from 'react-router-dom';

export default function Notifications({ email, logger, onBack, onClose }) {
  const [enable_push_news, setEnablePushNews] = useLocalStorage(
    'enable_push_news',
    null
  );
  const [enable_push_vip, setEnablePushVip] = useLocalStorage(
    'enable_push_vip',
    null
  );
  const [enable_push_bonus, setEnablePushBonus] = useLocalStorage(
    'enable_push_bonus',
    null
  );
  const allEnabled =
    enable_push_bonus === 'true' &&
    enable_push_vip === 'true' &&
    enable_push_news === 'true';

  useEffect(() => {
    persistUserProfile({
      enable_push_news,
      enable_push_vip,
      enable_push_bonus,
    });
  }, [enable_push_news, enable_push_vip, enable_push_bonus]);

  const [pushNotificationGranted, setPushNotificationGranted] = useLocalStorage(
    'push-notification-granted',
    false
  );
  const [showPushNotificationErrorDialog, setShowPushNotificationErrorDialog] =
    useState(false);

  const [showPushNotificationPrompt, setShowPushNotificationPrompt] =
    useState(false);

  const enableChecksCallBack = useRef();

  const { subscribe } = useSubscribe(logger);

  async function checkPushSubscription() {
    /*     if (process.env.NODE_ENV !== 'production') {
      setShowPushNotificationErrorDialog(true);
      return false;
    }
    */
    if (!isPwa() && !isNativeApp()) return false;
    if (!(await subscribe(email))) {
      setShowPushNotificationErrorDialog(true);
      logger?.event('push-notifications', { error: 'dialog' });
      return false;
    }
    return true;
  }

  async function CheckPermissions(callBack) {
    //if (process.env.NODE_ENV !== 'production') callBack();

    if (isTelegram()) {
      callBack();
    }

    if (!isPwa() && !isNativeApp()) return;

    if (!pushNotificationGranted) {
      setShowPushNotificationPrompt(true);
      enableChecksCallBack.current = callBack;
      return;
    }
    if (!(await checkPushSubscription())) return;
    callBack();
  }

  const switchers = [
    {
      id: 0,
      name: t('Bonuses'),
      descr: t('Rewards and bonuses'),
      type: 'switch',
      value: enable_push_bonus === 'true',
    },
    {
      id: 1,
      name: t('VIP'),
      descr: t('Special offers for VIP users'),
      type: 'switch',
      value: enable_push_vip === 'true',
    },
    {
      id: 2,
      name: t('News'),
      descr: t('The latest updates'),
      type: 'switch',
      value: enable_push_news === 'true',
    },
  ];

  const location = useLocation();

  return (
    <>
      <SystemDialog
        title={t('Failed')}
        show={showPushNotificationErrorDialog}
        message={
          <>
            {t(
              'Please enable notifications in your device Settings and restart the app'
            )}
            {isAndroid() && (
              <>
                <br />
                <br />
                {`Settings -> Apps -> Permissions -> Notifications -> Boxo`}
              </>
            )}
            {isIos() && isPwa() && (
              <>
                <br />
                <br />
                {`iOS 16.4 or later is required`}
              </>
            )}
          </>
        }
        onAction={() => {
          setShowPushNotificationErrorDialog(false);
        }}
      />

      <SystemDialog
        title={t('Allow notifications')}
        show={showPushNotificationPrompt}
        message={t('Boxo app would like to send you notifications')}
        actions={[
          isAndroid() ? t(`Block`) : t(`Don't allow`),
          isAndroid() ? t(`Allow`) : `OK`,
        ]}
        onAction={async (action) => {
          setShowPushNotificationPrompt(false);
          if (action === 1) {
            logger?.action('click-allow-notifications');
            setPushNotificationGranted(true);
            if (await checkPushSubscription()) enableChecksCallBack.current();
          } else logger?.action('click-disallow-notifications');
        }}
      />

      <ModalLayout onBack={onBack} onClose={onClose}>
        <div className="modal-header">
          <h1 className="text-center text-default">{t('Notifications')}</h1>
        </div>

        <div className="modal-body">
          <SelectionList
            header={t('Receive notifications')}
            items={switchers}
            onClick={(idx, isChecked) => {
              const value = isChecked ? 'true' : 'false';
              if (isChecked) {
                CheckPermissions(() => {
                  if (idx === 0) setEnablePushBonus(value);
                  if (idx === 1) setEnablePushVip(value);
                  if (idx === 2) setEnablePushNews(value);
                });
              } else {
                if (idx === 0) setEnablePushBonus(value);
                if (idx === 1) setEnablePushVip(value);
                if (idx === 2) setEnablePushNews(value);
              }
            }}
          />
        </div>

        <div className="modal-footer no-mobile-keyboard">
          {location.state === '/app/bonus/push' &&
            enable_push_bonus === 'true' && (
              <div
                className={`btn btn-primary`}
                role="button"
                onClick={() => {
                  onBack();
                }}
              >
                {t(`Contunue`)}
              </div>
            )}

          {(location.state !== '/app/bonus/push' ||
            enable_push_bonus !== 'true') && (
            <div
              className={`btn btn-primary`}
              role="button"
              onClick={() => {
                const value = allEnabled ? 'false' : 'true';

                if (!allEnabled) {
                  CheckPermissions(() => {
                    setEnablePushBonus(value);
                    setEnablePushVip(value);
                    setEnablePushNews(value);
                  });
                } else {
                  setEnablePushBonus(value);
                  setEnablePushVip(value);
                  setEnablePushNews(value);
                }
              }}
            >
              {allEnabled ? t(`Disable all`) : t(`Enable all`)}
            </div>
          )}
        </div>
      </ModalLayout>
    </>
  );
}

import { useMemo } from 'react';
import { useContext } from 'react';
import { createContext } from 'react';
import { useEffect, useState } from 'react';
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from 'react-circular-progressbar';
import { useLocalStorage } from './use-hooks';
import { useInterval } from './use-interval';

const Monetag = createContext();
export function MonetagContext({ children }) {
  const MonetagProps = useMonetag();
  const persistedMonetagProps = useMemo(() => MonetagProps, [MonetagProps]);
  return (
    <Monetag.Provider value={persistedMonetagProps}>
      {children}
    </Monetag.Provider>
  );
}
export const useMonetagContext = () => useContext(Monetag);

export function useMonetag() {
  const [adsEnabled, setAdsEnabled] = useState(false);

  const id = window.location.hostname === 'boxo.trade' ? '8077073' : '8078899';

  function getMonetagHeaderScript() {
    if (adsEnabled)
      return (
        <script
          async="async"
          data-cfasync="false"
          src={`//thubanoa.com/1?z=${id}`}
        />
      );
    return <script />;
  }

  function MonetagIns({ playTime = 6, onShow, onFailed, onFinished }) {
    useEffect(() => {
      setAdsEnabled(true);
    }, []);

    const [isShowing, setIsShowing] = useState(false);

    const clearInterval = useInterval(() => {
      const insId = `p_${id}`;
      const insElement = document.getElementById(insId);

      if (!isShowing) {
        if (insElement?.clientHeight > 0) {
          setIsShowing(true);
          onShow && onShow();
        }
        if (!insElement) {
          clearInterval();
          onFailed && onFailed('Monetag element not found');
        }
        if (insElement && insElement?.clientHeight === 0) {
          clearInterval();
          onFailed && onFailed('Monetag element is hidden');
        }
      } else if (insElement?.clientHeight === 0) {
        clearInterval();
        onFinished && onFinished();
      }
    }, 1500);

    const [adsStartTime, setAdsStartTime] = useLocalStorage(
      'show-ads-start-time',
      null
    );
    const showProgress =
      adsStartTime && (Date.now() - adsStartTime) / 1000 <= playTime;

    useEffect(() => {
      setAdsStartTime(Date.now());
    }, []);

    return (
      <>
        {!isShowing && (
          <div
            style={{
              position: 'relative',
              top: 0,
              left: '0.5rem',
              width: '40px',
              height: '40px',
            }}
            onClick={() => {
              if (!showProgress)
                onFailed && onFailed('Monetag element not found');
            }}
          >
            <div
              style={{
                width: '40px',
                height: '40px',
              }}
            >
              <CircularProgressbarWithChildren
                value={adsStartTime ? (Date.now() - adsStartTime) / 1000 : 0}
                maxValue={playTime}
                styles={buildStyles({
                  strokeLinecap: 'butt',
                  pathTransitionDuration: 0.3,
                  pathColor: showProgress
                    ? `var(--theme-muted)`
                    : 'transparent',
                  trailColor: showProgress
                    ? 'var(--theme-muted-transparent)'
                    : 'transparent',
                  backgroundColor: 'transparent',
                })}
              >
                <img
                  style={{
                    position: 'relative',
                    width: '30px',
                  }}
                  src="/img/close.svg"
                />
              </CircularProgressbarWithChildren>
            </div>
          </div>
        )}
      </>
    );
  }

  return { getMonetagHeaderScript, MonetagIns };
}

export function MonetagContainer({
  onDone = () => {},
  onFail = () => {},
  onShowing = () => {},
}) {
  const { MonetagIns } = useMonetagContext();

  const doDone = function () {
    document
      .querySelector('meta[name=viewport]')
      .setAttribute(
        'content',
        'width=device-width, height=device-height, initial-scale=1, maximum-scale=1, user-scalable=no, shrink-to-fit=no, viewport-fit=cover'
      );
    setTimeout(() => {
      onDone();
    }, 600);
  };

  return (
    <>
      <MonetagIns
        onShow={() => {
          onShowing();
        }}
        onFailed={(msg) => {
          onFail(msg);
        }}
        onFinished={() => {
          doDone();
        }}
      />
    </>
  );
}

import { useEffect, useState } from 'react';
import { t } from 'i18next';
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { ModalLayout } from '../shared/layout';
import { useBonusesContext } from '../shared/hooks/use-bonus';
import {
  isAndroid,
  isIos,
  isNativeApp,
  isPwa,
  isTelegram,
  millisecondsLeftToStr,
  tryToParseJSON,
} from '../shared/utils';
import {
  BtnIcon,
  SelectionList,
  TodoList,
  TodoListDone,
} from '../components/terminal';
import { InstallDialog } from './install';
import { Loader } from './loader';
import { SystemDialog } from '../shared/dialogs';
import { useLocalStorage, useSessionStorage } from '../shared/hooks/use-hooks';
import { useInterval } from '../shared/hooks/use-interval';
import { useSoundContext } from '../shared/hooks/use-sound';
import {
  loadPersistentState,
  persistSessionState,
  persistState,
} from '../shared/utils/persist';
import { useUserContext } from '../shared/hooks/use-user';
import { AdsenseContainer } from '../shared/hooks/use-adsense';
import { useLoggerContext } from '../shared/hooks/use-logger';
import { MonetagContainer } from '../shared/hooks/use-monetag';
import { useNativeAppContext } from '../shared/hooks/use-native-app';
import { AdMobContainer } from '../shared/hooks/use-admob';
import { claimBonusMachine } from '../shared/machines/bonus';
import { useMachine } from '@xstate/react';
import {
  BonusBlackBoxSvg,
  BonusBlackLidSvg,
  BonusBoxSvg,
} from '../components/svg';

function BonusListDialog({
  logger,
  email,
  onClaimBonus,
  onClaimBonusError,
  onLogin,
  onRestartGame,
  challengeBonusProgress,
}) {
  const { isLogged } = useUserContext();

  const [bonus, setBonus] = useState(null);
  const { getActualBonuses, claimBonus, isLoading } = useBonusesContext();

  const [bonuses, setBonuses] = useState([]);
  useState(null);
  const location = useLocation();

  useEffect(() => {
    async function update() {
      if (!email) {
        setBonuses([]);
      } else {
        if (isLoading) return;
        setBonuses(await getActualBonuses());
      }
    }
    update();
  }, [isLoading]);

  const [isClaiming, setIsClaiming] = useState(false);

  function doClaimBonus(claimedBonus) {
    setBonus(claimedBonus);
    navigate('./claim', { replace: true });
  }

  function doClaimBonusOld(claimedBonus) {
    /* 
    //DEBUG -- Prevent claiming
    const newBonus = { ...claimedBonus, claimedAmount: 0 };
    setBonus(newBonus);
    setIsClaiming(false);
    navigate('./success', { replace: true });
    return;
    /* */
    if (isClaiming) return;
    setIsClaiming(true);

    claimBonus(
      claimedBonus,
      (amount) => {
        const newBonus = { ...claimedBonus, claimedAmount: amount };
        setBonus(newBonus);
        setIsClaiming(false);
        navigate('./success', { replace: true });
      },
      (error, e) => {
        setIsClaiming(false);
        onClaimBonusError &&
          onClaimBonusError(claimedBonus, `${error}${e ? ' ' + e : ''}`);
      }
    );
  }

  const navigate = useNavigate();
  function doPlay() {
    persistSessionState(location.pathname, null); //Prevent <Back
    navigate('/app', { replace: true });
  }

  return (
    <>
      <Routes>
        {isLogged && (
          <>
            <Route
              path="/"
              element={
                isClaiming || isLoading ? (
                  <Loader overlay />
                ) : (
                  <BonusList
                    bonuses={bonuses}
                    logger={logger}
                    email={email}
                    onSetBonus={setBonus}
                  />
                )
              }
            />

            <Route
              path="/challenge"
              element={
                false && isLoading ? (
                  <Loader overlay />
                ) : (
                  <ChallengeBonusDialog
                    onClaimBonus={doClaimBonus}
                    onPlay={doPlay}
                    challengeBonusProgress={challengeBonusProgress}
                  />
                )
              }
            />

            <Route
              path="/welcome"
              element={
                <WelcomeBonusDialog
                  logger={logger}
                  onClaimBonus={doClaimBonus}
                  hidePushNotificationsTask={true}
                  isLoading={isLoading}
                />
              }
            />
            <Route
              path="/push"
              element={
                <WelcomeBonusDialog
                  logger={logger}
                  onClaimBonus={doClaimBonus}
                  hidePushNotificationsTask={false}
                  isLoading={isLoading}
                />
              }
            />
            <Route
              path="/recovery"
              element={
                <RecoveryBonusDialog
                  onClaimBonus={doClaimBonus}
                  onPlay={doPlay}
                />
              }
            />

            <Route
              path="/success"
              element={
                isClaiming ? (
                  <Loader overlay />
                ) : (
                  <SuccessBonusDialog
                    bonus={bonus}
                    onClaimedBonus={() => {
                      onClaimBonus(bonus);
                    }}
                  />
                )
              }
            />

            <Route
              path="/health"
              element={<HealthBonusDialog onClaimBonus={doClaimBonus} />}
            />

            <Route
              path="/claim"
              element={
                <ClaimBonusDialog
                  bonus={bonus}
                  onClaimedBonus={onClaimBonus}
                  onClaimBonusError={onClaimBonusError}
                />
              }
            />

            <Route path="/*" element={<NoBonusDialog />} />
          </>
        )}

        <Route
          path="/welcome/token/:token"
          element={
            <WelcomeBonusDialog
              logger={logger}
              onClaimBonus={doClaimBonus}
              hidePushNotificationsTask={true}
              onLogin={onLogin}
              isLoading={isLoading}
            />
          }
        />

        <Route
          path="/no-auth"
          element={
            <WelcomeBonusDialog logger={logger} onRestartGame={onRestartGame} />
          }
        />

        <Route path="/*" element={<WelcomeBonusDialog logger={logger} />} />
      </Routes>
    </>
  );
}

function BonusList({ bonuses, onSetBonus }) {
  const navigate = useNavigate();

  const onClose = () => {
    navigate('/app', { replace: true });
  };

  const location = useLocation();

  function getBonusAttr(bonus) {
    const bonusType = bonus?.typeId;
    if (bonusType === '2ndWelcomeBonusFreeBoxes')
      return {
        name: t('Welcome bonus'),
        imgUrl: '/img/bonus-box.svg',
      };
    if (bonusType === 'WelcomeBonusFreeBoxes')
      return {
        name: t('Welcome bonus'),
        imgUrl: '/img/bonus-box.svg',
      };
    if (bonusType === 'PushNotificationsBonus')
      return {
        name: t('Notifications bonus'),
        imgUrl: '/img/plane.svg',
      };
    if (bonusType === 'ChallengeBonus')
      return {
        name: t('Achievement bonus'),
        imgUrl: '/img/progress.svg',
      };
    if (bonusType === 'RecoveryBonus') {
      const enabled =
        bonus?.availableAt === null || bonus?.availableAt - Date.now() < 0;
      return {
        name: t('Recovery bonus'),
        descr: enabled ? null : t('Recently claimed'),
        imgUrl: '/img/heart.svg',
        disabled: !enabled,
      };
    }
    if (bonusType === 'AdBonus') {
      const enabled =
        bonus?.availableAt === null || bonus?.availableAt - Date.now() < 0;
      return {
        name: t('Health bonus'),
        descr: enabled ? null : t('Recently claimed'),
        imgUrl: '/img/health.svg',
        disabled: !enabled,
      };
    }
    return {
      name: t('Unknown bonus'),
      imgUrl: '/img/bonus-box.svg',
    };
  }
  function bonusOnClick(bonus) {
    if (!bonus) return;
    onSetBonus(bonus);
    if (
      bonus.typeId === '2ndWelcomeBonusFreeBoxes' ||
      bonus.typeId === 'WelcomeBonusFreeBoxes'
    ) {
      navigate('./welcome', {
        replace: true,
        state: location.pathname,
      });
    }

    if (bonus.typeId === 'PushNotificationsBonus') {
      navigate('./push', {
        replace: true,
        state: location.pathname,
      });
    }

    if (bonus.typeId === 'ChallengeBonus') {
      navigate('./challenge', {
        replace: true,
        state: location.pathname,
      });
    }
    if (bonus.typeId === 'RecoveryBonus') {
      navigate('./recovery', {
        replace: true,
        state: location.pathname,
      });
    }
    if (bonus.typeId === 'AdBonus') {
      navigate('./health', {
        replace: true,
        state: location.pathname,
      });
    }
  }

  return (
    <>
      {!bonuses.length && <NoBonusDialog onClose={onClose} />}

      {bonuses.length > 0 && (
        <ModalLayout onClose={onClose}>
          <div className="modal-header">
            <h1 className="text-center text-default">{t('Get bonus')}</h1>
          </div>
          <div className="modal-body">
            {bonuses && (
              <SelectionList
                items={bonuses.map((bonus, idx) => {
                  const { name, descr, imgUrl, disabled } = getBonusAttr(bonus);
                  return {
                    name,
                    descr,
                    imgUrl,
                    disabled,
                    //tag: bonus?.last_seen_at === null ? t('New') : null,
                    type: 'menu',
                  };
                })}
                onClick={(idx) => bonusOnClick(bonuses[idx])}
              />
            )}
          </div>
        </ModalLayout>
      )}
    </>
  );
}

function WelcomeBonusDialog({
  logger,
  onClaimBonus,
  hidePushNotificationsTask = true,
  onLogin,
  onRestartGame,
}) {
  const {
    isLoading,
    setBonusSeen,
    getActualWelcomeBonus,
    getActualPushNotificationsBonus,
  } = useBonusesContext();
  const { user } = useUserContext();
  const email = user?.email;
  const [bonus, setBonus] = useState(null);
  const [expired, setExpired] = useLocalStorage('WelcomeBonusExpiredAt', null);

  useEffect(() => {
    async function update() {
      if (!email) {
        const amount = 500;
        const newExpired =
          !expired || expired <= Date.now()
            ? Date.now() + 60 * 60 * 24 * 1000
            : expired;
        const newBonus = {
          params: { amount },
          expired: newExpired,
        };
        setExpired(newExpired);
        setBonus(newBonus);
      } else {
        const newBonus = hidePushNotificationsTask
          ? await getActualWelcomeBonus()
          : await getActualPushNotificationsBonus();
        setBonus(newBonus);
        setBonusSeen(newBonus);
      }
    }
    if (isLoading) return;
    update();
  }, [email, isLoading]);

  const navigate = useNavigate();
  const [isInstallDialogShowing, setIsInstallDialogShowing] = useState(false);
  const [enablePushBonus] = useLocalStorage('enable_push_bonus', null);
  const location = useLocation();
  const params = useParams();
  const { token } = params;
  const { initUser } = useUserContext();

  useEffect(() => {
    if (!token) return;
    if (email && isIos() && !isPwa()) {
      //Waiting for install in ios Safari
      //URL is already updated as a start_url for ios PWA
      //Ask user to Add to home screen
      setIsInstallDialogShowing(true);
    }
    const isIosPwaInstalled = loadPersistentState('isIosPwaInstalled');
    if (isIosPwaInstalled) {
      //alert('Normal ios start');
      //It's a normal start of PWA app on iOS
      //PWA's start_url is app/bonus/welcome/token/...
      //Going home
      navigate('/', { replace: true });
    } else {
      //First time PWA start on ios:
      if (
        (!email && isIos() && isPwa()) ||
        (!email && process.env.NODE_ENV !== 'production')
      ) {
        //alert('First ios pwa start');
        //Auth token is received!
        //This is one time code on PWA
        const newToken = tryToParseJSON(atob(token));
        const { email: newEmail, session } = newToken;
        persistState('session', session);
        persistState('email', newEmail);
        persistState('isIosPwaInstalled', true);
        initUser(newEmail).then(() => {
          onLogin(newEmail, '/app/bonus/welcome');
        });
      }
    }
  }, [token]);

  const onInstall = () => {
    logger?.event('click', { target: 'InstallForBonus' });
    if (!token && isIos()) {
      //Generate token for URL
      const session = loadPersistentState('session');
      const newToken = btoa(JSON.stringify({ email, session }));
      const startUrl = `/app/bonus/welcome/token/${newToken}`;
      window.location.replace(startUrl);
    } else if (isAndroid() || isIos()) setIsInstallDialogShowing(true);
    else alert('Please open this web site in mobile browser');
  };

  const onClose = () => {
    navigate('/app', { replace: true });
  };

  const onSignUpNavigate = () => {
    navigate('/app/auth/sign-up', { replace: true });
  };

  const onNotificationsNavigate = () => {
    navigate('/app/settings/notifications', {
      replace: true,
      state: location.pathname,
    });
  };

  const [adsWatched, setAdsWatched] = useState(false);
  const [showAds, setShowAds] = useSessionStorage('show-ads', false);

  useEffect(() => {
    if (adsWatched) onRestartGame && onRestartGame();
  }, [adsWatched]);

  return (
    <>
      {!bonus && !isLoading && <NoBonusDialog onClose={onClose} />}
      {!bonus && isLoading && <Loader overlay />}

      {bonus && (
        <ModalLayout
          hideNavigation={showAds}
          onClose={
            !email || (email && token && isIos() && !isPwa()) ? null : onClose
          }
        >
          {showAds && (
            <AdsContainer
              format="Short"
              onDone={() => {
                setAdsWatched(true);
                setShowAds(false);
              }}
              onFail={() => {
                setAdsWatched(false);
                setShowAds(false);
              }}
            />
          )}
          {!showAds && (
            <>
              <div className="modal-header">
                <h1 className="text-center text-default">{t('Get bonus')}</h1>
                {bonus.expired - Date.now() > 0 && (
                  <div className="text-center">
                    <img
                      src="/img/time.svg"
                      alt=""
                      style={{
                        width: '11px',
                        marginTop: '0px',
                        marginLeft: '0px',
                        marginRight: '6px',
                      }}
                    />
                    <span>
                      <small>
                        {millisecondsLeftToStr(
                          Math.max(0, bonus.expired - Date.now())
                        )}
                      </small>
                    </span>
                  </div>
                )}
              </div>
              <div className="modal-body">
                {hidePushNotificationsTask && (
                  <BonusCard
                    name={t('Welcome bonus')}
                    img="/img/black-box-flat.svg"
                    color="var(--theme-gold)"
                    amount={bonus?.params?.amount}
                    todoList={[
                      {
                        name: t('Create account'),
                        isDone: !!email,
                      },
                      {
                        name: isTelegram()
                          ? t('Start bot')
                          : isIos() && !isNativeApp()
                          ? t('Add to Home Screen')
                          : t('Install app'),
                        isDone: isPwa() || isNativeApp() || isTelegram(),
                      },
                    ]}
                  />
                )}
                {!hidePushNotificationsTask && (
                  <BonusCard
                    name={t('Notifications bonus')}
                    img="/img/black-plane-flat.svg"
                    color="var(--theme-crystal)"
                    amount={bonus?.params?.amount}
                    todoList={[
                      {
                        name: isTelegram()
                          ? t('Start Telegram bot')
                          : t('Install app'),
                        isDone: isPwa() || isNativeApp() || isTelegram(),
                      },
                      {
                        name: `${t('Enable notifications')}: "${t('Bonuses')}"`,
                        isDone: enablePushBonus === 'true',
                      },
                    ]}
                  />
                )}
              </div>

              <div className="modal-footer no-mobile-keyboard justify-content-center">
                {!email && (
                  <>
                    <div
                      className="btn btn-primary"
                      role="button"
                      onClick={onSignUpNavigate}
                    >
                      {t(`Register`)}
                    </div>

                    {!location.state && (
                      <div
                        className="btn btn-secondary"
                        role="button"
                        onClick={() => {
                          logger?.event('click', {
                            target: 'RestartDemo',
                          });
                          setShowAds(true);
                        }}
                      >
                        <BtnIcon src="/img/btn-play-video.svg" />
                        {t(`Restart Demo`)}
                      </div>
                    )}
                  </>
                )}
                {!!email && !isPwa() && !isNativeApp() && !isTelegram() && (
                  <div
                    className="btn btn-primary"
                    role="button"
                    onClick={onInstall}
                  >
                    {isIos() ? t('Add to Home Screen') : t(`Install`)}
                  </div>
                )}
                {!hidePushNotificationsTask &&
                  !!email &&
                  (isNativeApp() || isPwa() || isTelegram()) &&
                  enablePushBonus !== 'true' && (
                    <div
                      className="btn btn-primary"
                      role="button"
                      onClick={() => onNotificationsNavigate()}
                    >
                      {t(`Enable notifications`)}
                    </div>
                  )}
                {!!email &&
                  (isPwa() || isNativeApp() || isTelegram()) &&
                  (enablePushBonus === 'true' || hidePushNotificationsTask) && (
                    <div
                      className="btn btn-primary"
                      role="button"
                      onClick={() =>
                        onClaimBonus({
                          ...bonus,
                          bonusColor: hidePushNotificationsTask ? 'gold' : null,
                        })
                      }
                    >
                      {t(`Claim`)}
                    </div>
                  )}
              </div>
            </>
          )}
        </ModalLayout>
      )}

      {isIos() && (
        <SystemDialog
          title={t('Add to Home Screen')}
          show={isInstallDialogShowing}
          actions={[`OK`]}
          message={
            <>
              <div className="text-nowrap">
                <br />
                1. {t('Press the Share button')}
                <img
                  src="/img/ios/share.svg"
                  alt=""
                  style={{
                    width: '17px',
                    marginTop: '-9px',
                    marginLeft: '8px',
                    marginRight: '8px',
                  }}
                />
              </div>
              <div className="text-nowrap">
                2. {t(`Press 'Add to Home Screen'`)}
                <img
                  src="/img/ios/add.svg"
                  alt=""
                  style={{
                    width: '18px',
                    marginTop: '4px',
                    marginLeft: '8px',
                    marginRight: '8px',
                  }}
                />
              </div>
            </>
          }
          onAction={() => setIsInstallDialogShowing(false)}
        />
      )}
      {isAndroid() && (
        <InstallDialog
          logger={logger}
          show={isInstallDialogShowing}
          onClose={() => setIsInstallDialogShowing(false)}
        />
      )}
    </>
  );
}

function ClaimBonusDialog({ bonus, onClaimedBonus, onClaimBonusError }) {
  const { claimBonus } = useBonusesContext();
  const [claimBonusState, claimBonusSend] = useMachine(claimBonusMachine, {
    state: {
      ...claimBonusMachine.initialState,
      context: { ...claimBonusMachine.initialState.context, bonus },
    },
    services: {
      claimBonus: () =>
        new Promise(async (resolve, reject) => {
          try {
            if (process.env.NODE_ENV !== 'production')
              //return reject({ error: 'test error' });
              return resolve({
                claimedAmount: claimBonusState.context.boostUsed
                  ? bonus.params.amount * 3
                  : bonus.params.amount,
              });
            claimBonus(
              bonus, //context.boostUsed ? bonus.adBoost : 1
              (amount) => resolve({ claimedAmount: amount }),
              (error, e) => reject({ error: `${error}${e ? ' ' + e : ''}` }),
              claimBonusState.context.boostUsed
            );
          } catch (e) {
            onClaimBonusError(bonus, `${e.msg}`);
            console.error(e);
            reject();
          }
        }),
    },
  });

  useEffect(() => {
    if (!bonus) onClaimBonusError(null);

    if (claimBonusState.value === 'idle')
      claimBonusSend('START', { payload: bonus });

    //if (claimBonusState.hasTag('ads')) && process.env.NODE_ENV !== 'production')
    //  claimBonusSend('DONE');

    if (claimBonusState.value === 'exit')
      onClaimedBonus({
        ...bonus,
        claimedAmount: claimBonusState.context.claimedAmount,
      });
  }, [bonus, claimBonusState.value]);

  const bonusColor = claimBonusState.context.boostUsed
    ? 'var(--theme-gold)'
    : 'var(--theme-primary)';

  return (
    <>
      {claimBonusState.hasTag('loading') && <Loader />}
      {bonus && (
        <ModalLayout>
          {claimBonusState.hasTag('ads') &&
            (true || process.env.NODE_ENV === 'production') && (
              <AdsContainer
                format={
                  claimBonusState.hasTag('boost')
                    ? bonus.useBoost
                    : bonus.useAds
                }
                onDone={() => claimBonusSend('DONE')}
                onFail={() => claimBonusSend('DONE')}
              />
            )}
          {claimBonusState.hasTag('success') && (
            <>
              <div className="modal-header">
                <h1 className="text-center text-default">{t('Congrats!')}</h1>
                <div className="text-center text-muted">
                  <small>{t('Bonus is credited')}</small>
                </div>
              </div>
              <div className="modal-body">
                <BonusBox color={bonusColor} lidOpen={true} />
                <div className="mt-4">
                  <TodoListDone
                    bgColor={bonusColor}
                    header={
                      claimBonusState.hasTag('boost')
                        ? t('Total bonus')
                        : t('Bonus details')
                    }
                    caption={t('You earned {{amount}} BOXO', {
                      amount: claimBonusState.hasTag('boost')
                        ? claimBonusState.context.claimedAmount
                        : bonus.params.amount,
                    })}
                  />
                </div>
              </div>
            </>
          )}
          {claimBonusState.hasTag('failed') && (
            <>
              <div className="modal-header">
                <h1 className="text-center text-default">{'Failed'}</h1>
                <div className="text-center text-muted">
                  <small>Please try again later.</small>
                </div>
              </div>
              <div className="modal-body">
                <small>{claimBonusState.context.error}</small>
              </div>
            </>
          )}
          {claimBonusState.hasTag('boost') &&
            claimBonusState.hasTag('select') && (
              <>
                <div className="modal-header">
                  <h1 className="text-center text-default">
                    {t('Boost your bonus')}!
                  </h1>
                  {/*
                   <div className="text-center text-muted">
                    <small>{t('Increase your bonus')}</small>
                  </div>
                */}
                </div>
                <div className="modal-body">
                  {/*
                   <BonusBox color={'var(--theme-gold)'} lidOpen={false} />
                  */}
                  <BonusCard
                    name={t('Bonus boost')}
                    img="/img/black-rocket.svg"
                    amount={`×${bonus.adBoost ? bonus.adBoost : 20}`}
                    color="var(--theme-gold)"
                    todoList={null}
                  />
                </div>
              </>
            )}

          <div className="modal-footer no-mobile-keyboard justify-content-center">
            {claimBonusState.hasTag('boost') &&
              claimBonusState.hasTag('select') && (
                <>
                  <div
                    className="btn btn-primary"
                    role="button"
                    onClick={() => claimBonusSend('CLAIM_X2')}
                  >
                    <BtnIcon src="/img/btn-play-video-primary.svg" />
                    {t(`Claim`)}
                  </div>
                  <div
                    className="btn btn-secondary"
                    role="button"
                    onClick={() => claimBonusSend('CLAIM_X1')}
                  >
                    {t(`Skip`)}
                  </div>
                </>
              )}

            {claimBonusState.hasTag('success') && (
              <div
                className="btn btn-primary"
                role="button"
                onClick={() => claimBonusSend('DONE')}
              >
                {t(`Continue`)}
              </div>
            )}

            {claimBonusState.hasTag('failed') && (
              <div
                className="btn btn-primary"
                role="button"
                onClick={() =>
                  onClaimBonusError(bonus, claimBonusState.context.error)
                }
              >
                {t(`Continue`)}
              </div>
            )}
          </div>
        </ModalLayout>
      )}
    </>
  );
}

function SuccessBonusDialog({ bonus, onClaimedBonus }) {
  return (
    <>
      {bonus && (
        <ModalLayout>
          <div className="modal-header">
            <h1 className="text-center text-default">{t('Congrats!')}</h1>
            <div className="text-center text-muted">
              <small>{t('Bonus is credited')}</small>
            </div>
          </div>
          <div className="modal-body">
            <BonusBox color={bonus.bonusColor} lidOpen={true} />

            <div className="mt-4">
              <TodoList
                header={t('Bonus details')}
                items={[
                  {
                    name: t('You earned {{amount}} BOXO', {
                      amount: bonus.claimedAmount,
                    }),
                    isDone: true,
                  },
                ]}
              />
            </div>
          </div>

          <div className="modal-footer no-mobile-keyboard justify-content-center">
            {true && (
              <div
                className="btn btn-primary"
                role="button"
                onClick={() => onClaimedBonus(bonus)}
              >
                {t(`Continue`)}
              </div>
            )}
          </div>
        </ModalLayout>
      )}
    </>
  );
}

function ChallengeBonusDialog({
  onClaimBonus,
  onPlay,
  challengeBonusProgress,
}) {
  const {
    setBonusSeen,
    invalidateBonuses,
    isLoading,
    getActualChallengeBonus,
  } = useBonusesContext();
  const [bonus, setBonus] = useState(null);

  useEffect(() => {
    async function update() {
      const newBonus = await getActualChallengeBonus();
      setBonus(newBonus);
      setBonusSeen(newBonus);
    }
    if (isLoading) return;
    update();
  }, [isLoading]);

  useEffect(() => {
    setBonus({ ...bonus, progress: challengeBonusProgress });
  }, [challengeBonusProgress]);

  useInterval(() => {
    invalidateBonuses();
  }, 2500);

  const left =
    !bonus || !bonus.params
      ? 100
      : Math.max(0, bonus.params.limit - bonus.progress);

  const location = useLocation();
  const navigate = useNavigate();
  const onBack = location.state
    ? () => navigate(location.state, { replace: true })
    : () => navigate('/app', { replace: true });
  const unlocked = left <= 0;

  return (
    <>
      {!bonus && <NoBonusDialog onClose={onBack} />}
      {bonus && (
        <ModalLayout onBack={bonus?.useAds ? onBack : null}>
          <div className="modal-header">
            <h1 className="text-center text-default">{t('Get bonus')}</h1>

            {bonus.expired - Date.now() > 0 && (
              <div className="text-center">
                <img
                  src="/img/time.svg"
                  alt=""
                  style={{
                    width: '11px',
                    marginTop: '0px',
                    marginLeft: '0px',
                    marginRight: '6px',
                  }}
                />
                <span>
                  <small>
                    {millisecondsLeftToStr(
                      Math.max(0, bonus.expired - Date.now())
                    )}
                  </small>
                </span>
              </div>
            )}
          </div>
          <div className="modal-body">
            <BonusCard
              name={t('Achievement bonus')}
              img="/img/black-target.svg"
              amount={bonus?.params?.amount}
              color="var(--theme-emerald)"
              todoList={[
                {
                  name: t('Play to win {{limit}} boxes', {
                    limit: bonus?.params?.limit,
                  }),
                  isDone: bonus?.progress > 0,
                },
                {
                  name: t('Wins: {{progress}}', {
                    progress: bonus?.progress,
                  }),
                  isDone: bonus?.progress > 0,
                },
                {
                  name: t('Left: {{left}}', { left }),
                  isDone: unlocked,
                },
              ]}
            />
          </div>
          <div className="modal-footer no-mobile-keyboard justify-content-center">
            {!unlocked && (
              <div
                className="btn btn-primary"
                role="button"
                onClick={() => onPlay()}
              >
                {t(`Continue`)}
              </div>
            )}
            {unlocked && (
              <div
                className="btn btn-primary"
                role="button"
                onClick={() => onClaimBonus && onClaimBonus(bonus)} // setShowAds(true)}
              >
                {bonus?.useAds && (
                  <BtnIcon src="/img/btn-play-video-primary.svg" />
                )}
                {t(`Claim`)}
              </div>
            )}
          </div>
        </ModalLayout>
      )}
    </>
  );
}

function BonusBox({ color = '#3FEE76', lidOpen = false }) {
  const { bonus: bonusSound } = useSoundContext();
  const [lidTop, setLidTop] = useState(0);
  const [animate, setAnimate] = useState(false);
  const [show, setShow] = useState(false);
  const lidOpenPos = 50;
  const lidClosePos = 0;

  const nativeApp = useNativeAppContext();

  useEffect(() => {
    setTimeout(() => setShow(true), 200);

    if (lidOpen) {
      setTimeout(() => {
        setLidTop(lidOpenPos);
        setAnimate(true);
        //nativeApp.vibrate(400);
        nativeApp.haptics('success');
        bonusSound && bonusSound();
      }, 700);
    } else setLidTop(lidClosePos);
  }, [lidOpen, bonusSound]);

  useInterval(
    () => {
      if (lidOpen)
        setLidTop(lidTop === lidOpenPos ? lidOpenPos - 8 : lidOpenPos);
    },
    animate ? 1000 : null
  );

  const imgStyle = {
    position: 'absolute',
    transform: 'translateX(-50%)',
    left: '50%',
    transition: 'all 1s ease-in-out',
  };
  return (
    <>
      <div
        style={{
          position: 'relative',
          height: '30vh',
          minHeight: '30vh',
          width: '250px',
          left: '50%',
          transform: 'translateX(-50%)',
          opacity: show ? 1 : 0,
          transition: 'opacity 0.2s',
        }}
      >
        <BonusBlackBoxSvg
          width="55%"
          style={{
            ...imgStyle,
            top: `${82}px`,
          }}
        />

        <BonusBoxSvg
          style={{
            ...imgStyle,
            top: `${85 - lidTop + Math.ceil((2 * lidTop) / 3)}px`,
          }}
          width="55%"
          color={color}
        />
        <BonusBoxSvg
          style={{
            ...imgStyle,
            top: `${85 - lidTop + Math.ceil(lidTop / 3)}px`,
          }}
          width="55%"
          color={color}
        />
        <BonusBoxSvg
          style={{
            ...imgStyle,
            top: `${85}px`,
          }}
          width="55%"
          color={color}
        />
        <BonusBlackLidSvg
          width="55%"
          style={{
            ...imgStyle,
            top: `${55 - lidTop}px`,
          }}
        />
      </div>
    </>
  );
}

function RecoveryBonusDialog({ onPlay, onClaimBonus }) {
  const { balance } = useUserContext();
  const { setBonusSeen, getActualRecoveryBonus, isLoading } =
    useBonusesContext();
  const [bonus, setBonus] = useState(null);
  useEffect(() => {
    !isLoading &&
      getActualRecoveryBonus().then((newBonus) => {
        setBonus(newBonus);
        setBonusSeen(newBonus);
      });
  }, [isLoading]);

  const [adsWatched, setAdsWatched] = useState(false);
  const [showAds, setShowAds] = useSessionStorage('show-ads', false);

  const lowBalance = balance < Number(bonus?.params?.minBalance);
  const unlocked =
    bonus?.availableAt === null || bonus?.availableAt - Date.now() < 0;

  const location = useLocation();
  const navigate = useNavigate();
  const onBack = location.state
    ? () => navigate(location.state, { replace: true })
    : () => navigate('/app', { replace: true });

  useEffect(() => {
    if (lowBalance && unlocked && adsWatched) onClaimBonus(bonus);
  }, [lowBalance, unlocked, adsWatched]);

  return (
    <>
      {!bonus && <NoBonusDialog onClose={onBack} />}
      {bonus && (
        <ModalLayout
          hideBackground={showAds}
          hideNavigation={showAds}
          onBack={adsWatched ? null : 'default'}
        >
          {showAds && (
            <AdsContainer
              onDone={() => {
                setAdsWatched(true);
                setShowAds(false);
              }}
              onFail={() => {
                setAdsWatched(false);
                setShowAds(false);
              }}
            />
          )}

          <div className="modal-header">
            {!showAds && (
              <h1 className="text-center text-default">{t('Get bonus')}</h1>
            )}
          </div>
          <div className="modal-body">
            {!showAds && adsWatched && lowBalance && unlocked && (
              <Loader overlay />
            )}

            {!showAds && !adsWatched && (
              <BonusCard
                name={t('Recovery bonus')}
                img="/img/black-heart-flat.svg"
                amount={bonus?.params?.amount ? bonus?.params?.amount : 10}
                color="var(--theme-ruby)"
                todoList={[
                  {
                    name: t(`Recover your balance smaller than {{amount}}`, {
                      amount: bonus?.params?.minBalance
                        ? bonus?.params?.minBalance
                        : 10,
                    }),
                    isDone: bonus?.params?.minBalance
                      ? balance < Number(bonus?.params?.minBalance)
                      : false,
                  },
                  {
                    name: `${t('Claim the bonus once per hour')}${
                      bonus?.availableAt - Date.now() < 0
                        ? ''
                        : `. ${millisecondsLeftToStr(
                            Math.max(0, bonus?.availableAt - Date.now())
                          )}`
                    }`,
                    isDone:
                      bonus?.availableAt === null ||
                      bonus?.availableAt - Date.now() < 0,
                  },
                ]}
              />
            )}
          </div>

          {!showAds && (
            <div className="modal-footer no-mobile-keyboard justify-content-center">
              {!unlocked && (
                <div
                  className="btn btn-primary"
                  role="button"
                  onClick={() => onBack()}
                >
                  {t(`Back`)}
                </div>
              )}

              {!lowBalance && unlocked && (
                <div
                  className="btn btn-primary"
                  role="button"
                  onClick={() => onPlay()}
                >
                  {t(`Continue`)}
                </div>
              )}

              {lowBalance && unlocked && !adsWatched && (
                <div
                  className="btn btn-primary"
                  role="button"
                  onClick={() => onClaimBonus(bonus)} //setShowAds(true)
                >
                  {bonus?.useAds && (
                    <BtnIcon src="/img/btn-play-video-primary.svg" />
                  )}
                  {t(`Claim`)}
                </div>
              )}
            </div>
          )}
        </ModalLayout>
      )}
    </>
  );
}

function BonusCard({
  color = '#72B37B',
  img = '/img/black-box-flat.svg',
  name = t('Welcome bonus'),
  amount = 10,
  todoList = [
    { name: 'Task 1', isDone: true },
    { name: 'Task 2', isDone: false },
  ],
}) {
  return (
    <>
      <div
        style={{
          height: '30vh',
          minHeight: '30vh',
          background: `linear-gradient(80.96deg, ${color} -1.34%, rgba(33, 43, 52, 0.61) 210%)`,
          boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.7)',
          borderRadius: '24px',
          position: 'relative',
          opacity: 0.9,
        }}
      >
        <div className="row g-0 h-100 flex-nowrap">
          <div className="col-4">
            <img
              src={img}
              alt=""
              style={{
                transform: 'translate(-15%, -50%)',
                top: '50%',
                left: '50%',
                height: '40%',
                position: 'relative',
              }}
            />
          </div>
          <div className="col">
            <div
              style={{
                transform: 'translateY(-50%)',
                top: '47%',
                position: 'relative',
              }}
            >
              <div
                className="text-center fw-bolder fs-5"
                style={{ opacity: 0.7, padding: '10px 15%' }}
              >
                {name}
              </div>
              <div
                className="text-center"
                style={{
                  lineHeight: '3rem',
                  fontSize: '3.4rem',
                  letterSpacing: '-0.01em',
                  color: '#000000',
                  textShadow:
                    '0.5px 0.5px 0.5px white, -0.5px -0.5px 2px var(--theme-muted)',
                }}
              >
                {amount}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-4">
        <TodoList header={t('How to get bonus')} items={todoList} />
      </div>
    </>
  );
}

function NoBonusDialog() {
  const logger = useLoggerContext();
  const location = useLocation();
  const navigate = useNavigate();
  const onBack = location.state
    ? () => navigate(location.state, { replace: true })
    : () => navigate('/app', { replace: true });

  const [show, setShow] = useState(false);
  const clearInterval = useInterval(() => {
    setShow(true);
    clearInterval();

    logger?.event('error', {
      msg: `No bonus at ${window.location.pathname}`,
    });
  }, 700);

  const { getActualBonuses, invalidateBonuses } = useBonusesContext();

  return (
    <ModalLayout onBack={onBack} onClose={null}>
      {show && (
        <>
          <div className="modal-header">
            <h1 className="text-center"></h1>
            <div
              className="text-center text-muted"
              style={{ marginTop: '30vh' }}
            >
              <img
                src="/img/time.svg"
                alt=""
                style={{
                  width: '11px',
                  marginTop: '-2px',
                  marginLeft: '0px',
                  marginRight: '6px',
                  opacity: 0.4,
                }}
              />
              {t('You have no bonus yet')}
            </div>
          </div>
          <div className="modal-footer no-mobile-keyboard justify-content-center">
            <div
              className="btn btn-primary"
              role="button"
              onClick={() => {
                logger?.event('click', {
                  target: `Refresh bonus`,
                });
                invalidateBonuses();
                getActualBonuses();
              }}
            >
              {t(`Refresh`)}
            </div>
          </div>
        </>
      )}
    </ModalLayout>
  );
}

export function AdsContainer({ playTime, format, onDone, onFail, onShowing }) {
  const settings = loadPersistentState('settings');
  const logger = useLoggerContext();
  const { html5Click } = useSoundContext();

  const doFail = (status) => {
    console.error('AdsContainer failed: ', status, settings?.canSkipAds);
    //alert('Error: ' + JSON.stringify(status));
    try {
      logger.event('error', {
        msg: `ads failed: ${JSON.stringify(status)}`,
        ads: adsList[adsIdx].name,
      });
    } catch (e) {}
    html5Click();
    if (settings?.canSkipAds) onDone && onDone();
    else onFail && onFail();
  };
  const doDone = () => {
    logger.event('ads', { status: `done`, ads: adsList[adsIdx].name });
    html5Click();
    onDone && onDone();
  };

  const [supresOnShowing, setSupresOnShowing] = useState(false);
  const doShowing = () => {
    if (supresOnShowing) return;
    setSupresOnShowing(true);
    logger.event('ads', { status: 'showing', ads: adsList[adsIdx].name });
    onShowing && onShowing();
  };

  //TODO: select ads
  const adsIdx = isNativeApp() && isIos() ? 2 : 0;
  const adsList = [
    {
      name: 'Monetag',
      container: (
        <MonetagContainer
          format={format}
          onDone={doDone}
          onFail={doFail}
          onShowing={doShowing}
        />
      ),
    },
    {
      name: 'Adsense',
      container: (
        <AdsenseContainer
          format={format}
          playTime={playTime}
          onDone={doDone}
          onFail={doFail}
          onShowing={doShowing}
        />
      ),
    },
    {
      name: 'AdMob',
      container: (
        <AdMobContainer
          format={format}
          onDone={doDone}
          onFail={doFail}
          onShowing={doShowing}
        />
      ),
    },
  ];
  return <>{adsList[adsIdx].container}</>;
}

function HealthBonusDialog({ onClaimBonus }) {
  const { setBonusSeen, getActualAdsBonus, isLoading } = useBonusesContext();
  const [bonus, setBonus] = useState(null);

  useEffect(() => {
    !isLoading &&
      getActualAdsBonus().then((newBonus) => {
        setBonus(newBonus);
        setBonusSeen(newBonus);
      });
  }, [isLoading]);

  const [adsWatched, setAdsWatched] = useState(false);
  const [showAds, setShowAds] = useSessionStorage('show-ads', false);

  const unlocked =
    bonus?.availableAt === null || bonus?.availableAt - Date.now() < 0;

  const location = useLocation();
  const navigate = useNavigate();
  const onBack = location.state
    ? () => navigate(location.state, { replace: true })
    : () => navigate('/app', { replace: true });

  useEffect(() => {
    if (unlocked && adsWatched) onClaimBonus(bonus);
  }, [unlocked, adsWatched]);

  return (
    <>
      {!bonus && <NoBonusDialog onClose={onBack} />}

      {bonus && (
        <ModalLayout
          hideBackground={showAds}
          hideNavigation={showAds}
          onBack={adsWatched ? null : 'default'}
        >
          {showAds && (
            <AdsContainer
              onDone={() => {
                setAdsWatched(true);
                setShowAds(false);
              }}
              onFail={() => {
                setAdsWatched(false);
                setShowAds(false);
              }}
            />
          )}

          <div className="modal-header">
            {!showAds && (
              <h1 className="text-center text-default">{t('Get bonus')}</h1>
            )}
          </div>
          <div className="modal-body">
            {!showAds && adsWatched && <Loader overlay />}

            {!showAds && !adsWatched && (
              <BonusCard
                name={t('Health bonus')}
                img="/img/black-health.svg"
                amount={bonus?.params?.amount ? bonus?.params?.amount : 10}
                color="var(--theme-ruby)"
                todoList={[
                  {
                    name: `${t('Claim the bonus once per hour')}${
                      bonus?.availableAt - Date.now() < 0
                        ? ''
                        : `. ${millisecondsLeftToStr(
                            Math.max(0, bonus?.availableAt - Date.now())
                          )}`
                    }`,
                    isDone:
                      bonus?.availableAt === null ||
                      bonus?.availableAt - Date.now() < 0,
                  },
                ]}
              />
            )}
          </div>

          {!showAds && (
            <div className="modal-footer no-mobile-keyboard justify-content-center">
              {!unlocked && (
                <div
                  className="btn btn-primary"
                  role="button"
                  onClick={() => onBack()}
                >
                  {t(`Back`)}
                </div>
              )}

              {unlocked && !adsWatched && (
                <div
                  className="btn btn-primary"
                  role="button"
                  onClick={() => onClaimBonus(bonus)} //setShowAds(true)}
                >
                  {bonus?.useAds && (
                    <BtnIcon src="/img/btn-play-video-primary.svg" />
                  )}
                  {t(`Claim`)}
                </div>
              )}
            </div>
          )}
        </ModalLayout>
      )}
    </>
  );
}

export { BonusListDialog };

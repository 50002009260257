import { t } from 'i18next';
import { useRef, useState } from 'react';
import { createContext } from 'react';
import { useContext } from 'react';
import { useMemo } from 'react';
import { useEffect } from 'react';
import {
  getBonuses,
  getChallengeBonusProgress,
  persistBonusSeen,
  claimBonusPersisted,
} from '../api/bonus';
import { useLocalStorage, useSessionStorage } from './use-hooks';
import { useInterval } from './use-interval';
import { useUserContext } from './use-user';

const Bonuses = createContext();
export function BonusesContext({ children }) {
  const bonusesProps = useBonuses();
  const persistedBonusesProps = useMemo(() => bonusesProps, [bonusesProps]);
  return (
    <Bonuses.Provider value={persistedBonusesProps}>
      {children}
    </Bonuses.Provider>
  );
}
export const useBonusesContext = () => useContext(Bonuses);

function useBonuses() {
  //Old format: {name: "Bonus", descr: "Amount $5", amount: "500", expiration: 1703361351044, claimed: true}
  const [bonuses, setBonuses] = useLocalStorage('bonuses', []);
  const [bonusTime, setBonusTime] = useSessionStorage('new-user-bonus-time', 0);

  const [isLoading, setIsLoading] = useState(false);
  const { balance, isLogged } = useUserContext();

  const isDirty = useRef();
  useEffect(() => {
    isDirty.current = true;
  }, []);

  function getBonusAttr(bonus) {
    const bonusType = bonus?.typeId;
    if (bonusType === '2ndWelcomeBonusFreeBoxes')
      return {
        name: t('Welcome bonus'),
        imgUrl: '/img/bonus-box.svg',
        hidden: false,
        isNew: false,
        adBoost: 0,
        useAds: false,
        useAdBoost: false,
        order: 1,
      };
    if (bonusType === 'WelcomeBonusFreeBoxes')
      return {
        name: t('Welcome bonus'),
        imgUrl: '/img/bonus-box.svg',
        hidden: false,
        isNew: true,
        adBoost: 0,
        useAds: false,
        useAdBoost: false,
        order: 1,
      };
    if (bonusType === 'PushNotificationsBonus')
      return {
        name: t('Notifications bonus'),
        imgUrl: '/img/plane.svg',
        hidden: false,
        isNew: true,
        adBoost: 0,
        useAds: false,
        useAdBoost: false,
        order: 2,
      };
    if (bonusType === 'ChallengeBonus')
      return {
        name: t('Achievement bonus'),
        imgUrl: '/img/target.svg', //progress.svg',
        hidden: false,
        isNew: bonus?.params?.limit - bonus?.progress <= 0,
        adBoost: bonus?.params?.adBoost ?? 0,
        useAds: false, //bonus?.params?.adBoost ? 'Short' : false, //TODO random
        useAdBoost: bonus?.params?.adBoost ? 'Long' : false,
        order: 3,
      };
    if (bonusType === 'RecoveryBonus') {
      const enabled =
        bonus?.availableAt === null || bonus?.availableAt - Date.now() < 0;
      return {
        name: t('Recovery bonus'),
        descr: enabled ? null : t('Recently claimed'),
        imgUrl: '/img/heart.svg',
        disabled: !enabled,
        hidden: balance >= 50,
        isNew: enabled,
        adBoost: bonus?.params?.adBoost ?? 0,
        useAds: false, //bonus?.params?.adBoost ? 'Long' : false,
        useAdBoost: bonus?.params?.adBoost ? 'Long' : false,
        order: 2,
      };
    }
    if (bonusType === 'AdBonus') {
      const enabled =
        bonus?.availableAt === null || bonus?.availableAt - Date.now() < 0;
      return {
        name: t('Health bonus'),
        descr: enabled ? null : t('Recently claimed'),
        imgUrl: '/img/health.svg',
        disabled: !enabled,
        hidden: false,
        isNew: enabled,
        adBoost: bonus?.params?.adBoost ?? 0,
        useAds: false, //bonus?.params?.adBoost ? 'Long' : false,
        useAdBoost: bonus?.params?.adBoost ? 'Long' : false,
        order: 4,
      };
    }
    return {
      name: t('Unknown bonus'),
      imgUrl: '/img/bonus-box.svg',
      hidden: true,
    };
  }

  async function getActualBonusData() {
    const result = isDirty?.current ? await fetchData() : bonuses;
    return result ?? [];
  }

  async function fetchData() {
    //console.log('================ UPDATE BONUSES ============');

    setIsLoading(true);

    function parse(params) {
      try {
        return params ? JSON.parse(params) : null;
      } catch {
        return { amount: 0 };
      }
    }

    try {
      const resps = await Promise.all([
        getBonuses(),
        getChallengeBonusProgress(),
      ]);
      const [bonusResponse, challengeBonusProgress] = resps;
      const progress = challengeBonusProgress.progress;
      const data = bonusResponse?.bonuses;

      const newBonuses = data
        ?.map((b) => {
          const newBonus = {
            ...b,
            typeId: b.type_id,
            created: b.created_at ? new Date(b.created_at).getTime() : null,
            availableAt: b.available_at
              ? new Date(b.available_at).getTime()
              : null,
            expired: b.expired_at
              ? new Date(b.expired_at).getTime()
              : bonusTime,
            lastSeenAt: b.last_seen_at
              ? new Date(b.last_seen_at).getTime()
              : null,
            type: b.type,
            params: parse(b.params),
            descr: '',
            progress: b.type_id === 'ChallengeBonus' ? progress : null,
          };
          const attr = getBonusAttr(newBonus);
          return { ...newBonus, ...attr };
        })
        .sort((a, b) => a.order - b.order); //a.name.localeCompare(b.name));
      setBonuses(newBonuses);
      isDirty.current = false;
      setIsLoading(false);
      return newBonuses;
    } catch (e) {
      setBonuses([]);
      setIsLoading(false);
      console.error(e);
      return [];
    }
  }

  useEffect(() => {
    if (!bonusTime) setBonusTime(Date.now() + 24 * 60 * 60 * 1000);
    if (!isLogged) setBonuses([]);
    else fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged]);

  function claimBonus(bonus, onSuccess, onFailed, useAdBoost = false) {
    if (!isLogged) {
      onFailed('Can not claim bonus. Please login.');
      return;
    }
    claimBonusPersisted(bonus, useAdBoost)
      .then((data) => {
        if (data?.success) {
          const amount = data.amount * 1;
          const newBonuses = bonuses.filter((b) => !(b.id === bonus.id));
          setBonuses(newBonuses);
          onSuccess(amount);
          //fetchData();
          invalidateBonuses();
        } else {
          if (data?.error === 'bonus is not found')
            onFailed(t('Bonus has been already spent'));
          else if (data?.error) onFailed(`Bonus error: ${data?.error}`);
          else onFailed(`Unknown bonus error: ${bonus?.id}`);
        }
      })
      .catch((e) => {
        onFailed(e);
      });
  }

  function setBonusSeen(bonus) {
    if (!isLogged || !bonus) return;
    persistBonusSeen(bonus);
  }

  async function getActualBonuses() {
    const bonusData = await getActualBonusData();
    return bonusData
      ?.map((b) => {
        const attr = getBonusAttr(b);
        return { ...b, ...attr };
      })
      .filter((b) => !b.hidden && (!b.expired || b.expired > Date.now()));
  }

  useInterval(() => {
    fetchData();
  }, 20000);

  async function getActualChallengeBonus() {
    const bonusData = await getActualBonusData();
    return bonusData.find(
      (b) => b.typeId === 'ChallengeBonus' && b.expired > Date.now()
    );
  }

  async function getActualWelcomeBonus() {
    const bonusData = await getActualBonusData();
    return bonusData.find(
      (b) => b.typeId === 'WelcomeBonusFreeBoxes' && b.expired > Date.now()
    );
  }

  async function getActualRecoveryBonus() {
    const bonusData = await getActualBonusData();
    return bonusData.find((b) => b.typeId === 'RecoveryBonus');
  }

  async function getActualPushNotificationsBonus() {
    const bonusData = await getActualBonusData();
    return bonusData.find(
      (b) => b.typeId === 'PushNotificationsBonus' && b.expired > Date.now()
    );
  }

  async function getActualAdsBonus() {
    const bonusData = await getActualBonusData();
    return bonusData.find((b) => b.typeId === 'AdBonus');
  }

  async function getActualNewBonuses() {
    const bonusData = await getActualBonusData();
    return bonusData.filter((b) => b.isNew && !b.hidden);
  }

  function invalidateBonuses() {
    isDirty.current = true;
  }

  return {
    invalidateBonuses,
    getActualBonuses,
    claimBonus,
    setBonusSeen,
    isLoading,
    getActualChallengeBonus,
    getActualWelcomeBonus,
    getActualPushNotificationsBonus,
    getActualRecoveryBonus,
    getActualAdsBonus,
    getActualNewBonuses,
  };
}

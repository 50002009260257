import { t } from 'i18next';
import { ModalLayout } from '../../shared/layout';
import { deleteUser, logout } from '../../shared/api/auth';
import { useLocation, useNavigate } from 'react-router-dom';
import { priceFmt } from '../../shared/utils';
import { SystemDialog } from '../../shared/dialogs';

export default function Privacy({ onClose, onLogout }) {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <>
      <ModalLayout onClose={onClose}>
        <div className="modal-body no-scroll text-center">
          <iframe
            style={{ height: '100%', width: '100%' }}
            src="/privacy.html"
          />
        </div>
      </ModalLayout>
    </>
  );
}

import { useContext } from 'react';
import { createContext } from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { useMemo } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithTelegram } from '../api/auth';
import { isIos, isTelegram } from '../utils';
import { useLocalStorage } from './use-hooks';

const Telegram = createContext();

export function TelegramContext({ children }) {
  const TelegramProps = useTelegram();
  const persistedTelegramProps = useMemo(() => TelegramProps, [TelegramProps]);
  return (
    <Telegram.Provider value={persistedTelegramProps}>
      {children}
    </Telegram.Provider>
  );
}
export const useTelegramContext = () => useContext(Telegram);

const initInset = {
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
};

function useTelegram() {
  const [inset, setInset] = useState(initInset);
  const [safeAreaInset, setSafeAreaInset] = useState(initInset);
  const [constentSafeAreaInset, setContentSafeAreaInset] = useState(initInset);

  const [user, setUser] = useLocalStorage('tg_user', {
    id: '',
    user_name: '',
    first_name: '',
    last_name: '',
    language_code: 'en',
    allows_write_to_pm: false,
    photo_url: '',
  });

  const navigate = useNavigate();
  const onSafeAreaChange = () =>
    setSafeAreaInset(window.Telegram?.WebApp?.safeAreaInset);
  const onContentSafeAreaChange = () =>
    setContentSafeAreaInset(window.Telegram?.WebApp?.contentSafeAreaInset);
  useEffect(() => {
    const minTop = isIos() ? 46 + 48 : 0;
    const newInset = {
      top: Math.max(
        minTop,
        constentSafeAreaInset?.top
          ? constentSafeAreaInset?.top
          : 0 + safeAreaInset?.top
          ? safeAreaInset?.top
          : 0
      ),
      left: constentSafeAreaInset?.left + safeAreaInset?.left,
      right: constentSafeAreaInset?.right + safeAreaInset?.right,
      bottom: 0, //constentSafeAreaInset.bottom + safeAreaInset.bottom,
    };
    setInset(newInset);
  }, [safeAreaInset, constentSafeAreaInset]);

  const [initDataRaw, setInitDataRaw] = useLocalStorage('tg_initDataRaw', '');
  const [nickname, setNickname] = useLocalStorage('nickname', '');

  async function signIn() {
    const resp = await signInWithTelegram(initDataRaw);
    if (resp?.success) {
      const newUser = {
        ...user,
        email: resp.email,
      };
      setUser(newUser);
      return { user: newUser, isNewUser: resp?.isNewUser };
    } else return { error: resp?.error };
  }

  useEffect(() => {
    const init = () => {
      try {
        window.Telegram?.WebApp?.expand();
        try {
          if (!window.Telegram?.WebApp?.isFullscreen)
            window.Telegram?.WebApp?.requestFullscreen();
        } catch {}
        window.Telegram?.WebApp?.disableVerticalSwipes();
        const newInitData = window.Telegram?.WebApp?.initData;
        if (newInitData !== '') {
          setInitDataRaw(newInitData);

          const data = new URLSearchParams(newInitData);
          const newUser = JSON.parse(decodeURIComponent(data.get('user')));
          setUser(newUser);
          const newNickname = newUser?.first_name
            ? newUser?.first_name
            : newUser?.username
            ? newUser?.username
            : newUser?.id;
          setNickname(newNickname);
        }
        window.Telegram?.WebApp?.onEvent('safeAreaChanged', onSafeAreaChange);
        window.Telegram?.WebApp?.onEvent(
          'contentSafeAreaChanged',
          onContentSafeAreaChange
        );

        const color = '#212b34';
        window.Telegram?.WebApp?.setHeaderColor(color);
        window.Telegram?.WebApp?.setBackgroundColor(color);
        window.Telegram?.WebApp?.setBottomBarColor(color);
        window.Telegram?.WebApp?.setBackgroundColor(color);
        window.Telegram?.WebApp?.enableClosingConfirmation();

        window.Telegram?.WebApp?.SettingsButton.show();
        window.Telegram?.WebApp?.SettingsButton?.onClick(() =>
          navigate('/app/settings', {
            replace: true,
            state: window.location.pathname,
          })
        );
      } catch (e) {
        //alert('Telegram init error: ' + JSON.stringify(e));
      }
    };
    if (isTelegram()) init();
  }, []);

  const callbackRef = useRef();
  const onBackClickRef = useRef();

  const setOnBackButton = (callback) => {
    callbackRef.current = callback;
    if (!callback) {
      window.Telegram?.WebApp?.BackButton?.offClick(onBackClickRef.current);
      window.Telegram?.WebApp?.BackButton?.hide();
      onBackClickRef.current = null;
    } else {
      const newOnBackClick = () => {
        window.Telegram?.WebApp?.BackButton?.offClick(onBackClickRef.current);
        window.Telegram?.WebApp?.BackButton?.hide();
        onBackClickRef.current = null;
        callbackRef.current && callbackRef.current();
        callbackRef.current = null;
      };
      !window.Telegram?.WebApp?.BackButton?.isVisible &&
        window.Telegram?.WebApp?.BackButton?.show();

      window.Telegram?.WebApp?.BackButton?.offClick(onBackClickRef.current);
      onBackClickRef.current = newOnBackClick;
      window.Telegram?.WebApp?.BackButton?.onClick(onBackClickRef.current);
    }
  };

  return { user, inset, setOnBackButton, signIn };
}

import { useEffect, useState } from 'react';
import { t } from 'i18next';
import {
  isNativeApp,
  isTelegram,
  millisecondsLeftToStr,
  priceFmt,
  priceFmtThousands,
} from '../shared/utils';
import { useInterval } from '../shared/hooks/use-interval';
import { useRef } from 'react';
import { useLocalStorage } from '../shared/hooks/use-hooks';
import { useNativeAppContext } from '../shared/hooks/use-native-app';

function DebugControl({ text1, text2, text3, onPause = () => {} }) {
  const style = {
    right: '4vw',
    width: '120px',
    tranform: 'translateX(-50%)',
    position: 'absolute',
    height: '40px',
    zIndex: 100,
    lineHeight: '42px',
    textAlign: 'center',
    top: '150px',
  };

  return (
    <>
      <div style={style}>
        <button onClick={() => onPause()} height="10px">
          Pause price
        </button>
        <div>{text1}</div>
        <div>{text2}</div>
        <div>{text3}</div>
      </div>
    </>
  );
}

function BackgoundLight({ top, height }) {
  return (
    <div
      className=""
      style={{
        overflow: 'hidden',
        position: 'absolute',
        zIndex: 0,
        width: '100%',
        height: height,
        top: top,
        background: `radial-gradient(circle at 0% 0, #b1f5ff10, transparent 30%),
          radial-gradient(circle at 100% 0%, #b1f5ff10, transparent 30%) 
          `,
      }}
    />
  );
}

function BlurPanel({ top, height = '50vh' }) {
  return (
    <div
      className="blur-filter"
      style={{
        position: 'absolute',
        zIndex: 9,
        width: '100%',
        height: height,
        minHeight: height,
        top: top,
      }}
    />
  );
}
function AnimatedHand({ y = '90%' }) {
  return (
    <>
      <div
        className="animated-hand"
        style={{
          position: 'absolute',
          //bottom: '10%',
          top: y,
          zIndex: 15,
        }}
      >
        <img src="/img/hand.svg" width="70px" />
      </div>
    </>
  );
}
function BetControl({
  label = '', //'SELECT BET SIZE', //Select a box to make a bet
  showing = true,
  onSetBet,
  onError,
  bet = 1,
  minBet = 1,
  maxBet = 1000000,
  bets = [1, 5, 10, 50, 100, 500, 1000, 5000, 10000, 50000, 100000],
  top = 46.8,
  ...props
}) {
  const [idx, setIdx] = useState(0);
  const maxIdx = bets.length - 1;

  useEffect(() => {
    const idx = bets.findIndex((b) => b >= bet);
    setIdx(idx >= 0 ? idx : 0);
  }, [bet]);

  const style = {
    right: '4vw',
    top: `${top}`,
    tranform: 'translateX(-50%)',
  };

  const labelStyle = {
    right: '4vw',
    width: '120px',
    tranform: 'translateX(-50%)',
    position: 'absolute',
    height: '40px',
    zIndex: -100,
    lineHeight: '42px',
    textAlign: 'center',
  };

  const topLabelStyle = {
    top: `calc(${top} - 34px)`,
  };

  return (
    <>
      <div className={showing ? 'visible' : 'hidden'}>
        <small
          className="text-muted text-uppercase"
          style={{ ...labelStyle, ...topLabelStyle }}
        >
          {label}
          {
            //t('Bet')} ${priceFmt(bets[idx] / 1000, 3)
          }
          {t('Box price')}
          {
            //t('Bet')
          }
        </small>
        <div
          {...props}
          className={`bet-controller blur-filter ${props.className}`}
          style={style}
        >
          <div
            role="button"
            className="btn btn-secondary btn-left"
            onClick={() => {
              const newIdx = idx <= 0 ? 0 : idx - 1;
              if (idx > 0 && bets[newIdx] >= minBet) {
                setIdx(newIdx);
                onSetBet && onSetBet(bets[newIdx]);
              }
            }}
          >
            -
          </div>
          <div className="text-primary glow">
            {priceFmtThousands(bets[idx], 0)}
          </div>
          <div
            role="button"
            disabled={idx >= maxIdx}
            className="btn btn-secondary btn-right"
            onClick={() => {
              const newIdx = idx >= maxIdx ? idx : idx + 1;
              if (bets[newIdx] <= maxBet) {
                setIdx(newIdx);
                onSetBet && onSetBet(bets[newIdx]);
              } else onError && onError();
            }}
          >
            +
          </div>
        </div>
      </div>
    </>
  );
}

function SoundControl({ top, ...props }) {
  useEffect(() => {}, []);
  const style = {
    right: '4vw',
    top: `${top}`,
    tranform: 'translateX(-50%)',
  };

  const [enableSonuds, setEnableSonuds] = useLocalStorage(
    'enable-sounds',
    true
  );

  return (
    <>
      <div>
        <div
          {...props}
          className={`sound-controller blur-filter ${props.className}`}
          style={style}
          onClick={() => {
            setEnableSonuds(!enableSonuds);
          }}
        >
          <div className="text-primary btn btn-secondary" role="button">
            <img
              src={
                enableSonuds ? '/img/sounds.svg' : '/img/sounds-disabled.svg'
              }
              alt=""
              style={{
                maxWidth: '22px',
                transform: 'translateY(-2px)',
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

function BonusItem({ amount, name, descr, expiration, onClick = () => {} }) {
  const [countDown, setCountDown] = useState('00:00:00');
  useInterval(() => {
    if (expiration) {
      const newCountDown = millisecondsLeftToStr(
        Math.max(0, expiration - Date.now())
      );
      setCountDown(newCountDown);
    }
  }, 500);
  return (
    <div
      role="button"
      style={{
        background:
          'linear-gradient(174deg, rgb(114, 179, 123) -10.34%, rgba(33, 43, 52, 0.61) 107.72%)',
        borderRadius: '8px',
        padding: '8px',
        margin: '10px 0',
      }}
      onClick={() => onClick({ amount, name, descr, expiration })}
    >
      <div className="" style={{ display: 'flex', flexWrap: 'wrap' }}>
        <div
          className="col-3"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div>
            <div
              style={{
                color: 'fff',
                lineHeight: '70px',
                fontSize: '2.3rem',
                textShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
                letterSpacing: '-1px',
              }}
            >
              {amount}
            </div>
          </div>
        </div>
        <div
          className="col"
          style={{
            display: 'grid',
            alignContent: 'center',
            justifyItems: 'start',
          }}
        >
          <div
            style={{
              color: 'fff',
              fontWeight: 300,
              lineHeight: '20px',
              textShadow: '0px 1px 1px rgba(0, 0, 0, 0.4)',
              marginBottom: '4px',
            }}
          >
            {name}
          </div>
          {descr && (
            <div>
              <div
                className="text-default"
                style={{
                  lineHeight: '14px',
                  fontSize: '0.675em',
                  fontWeight: 200,
                  opacity: 0.8,
                }}
              >
                {descr}
              </div>
            </div>
          )}
        </div>

        <div
          className="col-auto text-monospace"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
            padding: '0 14px',
          }}
        >
          <span className="text-primary-accent">
            <sup
              style={{
                fontWeight: 100,
                fontSize: '0.7rem',
                top: '-0.14rem',
                left: '-0.3rem',
                //textShadow: '0px 1px 1px rgba(0, 0, 0, 0.4)',
              }}
            >
              ●
            </sup>
          </span>

          <span
            style={{
              opacity: 0.6,
              fontSize: '0.675em',
              fontWeight: 400,
            }}
          >
            {countDown}
          </span>
        </div>
      </div>
    </div>
  );
}

function SelectionList({
  items,
  activeItemIdx = -1,
  height,
  header = '',
  onClick = () => {},
}) {
  /*  items = [{ id, name, descr, imgUrl, hideSubmenu, type: menu | switch | button, tag, tagMuted, value, isActive, disabled }]   */
  const scrollerRef = useRef();
  const activeItemRef = useRef();
  const [scrolledToItemIdx, setScrolledToItemIdx] = useState(-1);

  useEffect(() => {
    const current = activeItemRef?.current?.parentElement;
    if (activeItemIdx >= 0 && current) {
      const parent = current.parentElement;
      //console.log('parent.pageYOffset=======', parent.pageYOffset);
      //if (Math.abs(parent.scrollY - current.offset) > 300)
      parent.scroll({
        top: current.offsetTop - 145,
        behavior: scrolledToItemIdx === -1 ? 'instant' : 'instant', //
      });
      setScrolledToItemIdx(activeItemIdx);
    }
  }, [activeItemIdx, activeItemRef?.current]);

  return (
    <>
      {header && (
        <div
          className="text-muted text-uppercase"
          style={{ padding: '2px 16px' }}
        >
          <small>{header}</small>
        </div>
      )}
      <ul
        className="list-group list-group-flush"
        style={{
          overflowY: 'scroll',
          overflowScrolling: 'touch',
          scrollbarWidth: 'none',
          height: `${height}`,
          minHeight: `${height}`,
          overscrollBehavior: 'contain', //none
        }}
        ref={scrollerRef}
      >
        {items &&
          items.map((item, idx) => {
            const id = item.id ?? idx;
            const refElement =
              idx === activeItemIdx ? (
                <div
                  style={{ height: '100%', position: 'absolute' }}
                  ref={activeItemRef}
                />
              ) : (
                <div />
              );
            return (
              item?.type && (
                <li
                  key={idx}
                  className={`list-group-item list-group-item-action ${
                    item.isActive ? 'active' : ''
                  }`}
                  role="button"
                  onClick={() => {
                    item.type !== 'switch' && onClick && onClick(id);
                  }}
                >
                  {refElement}
                  <div className="row">
                    {item?.imgUrl && (
                      <div
                        className="col-2"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <img
                          src={item.imgUrl}
                          alt=""
                          style={{
                            //opacity: item.disabled ? 0.3 : 1,
                            width: '100%',
                            maxWidth: '40px',
                          }}
                        />
                      </div>
                    )}
                    <div className="col" style={{ alignSelf: 'center' }}>
                      <div
                        className={`text-wrap${
                          item.disabled ? ' text-muted' : ''
                        }`}
                        style={{ lineHeight: '20px' }}
                      >
                        {item.name}
                      </div>
                      <div>
                        <small className="text-muted text-nowrap">
                          {item.descr}
                        </small>
                      </div>
                    </div>

                    <div className="col my-auto">
                      <div
                        className="float-end"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'right',
                        }}
                      >
                        {
                          //(item?.type === 'menu' || item?.type === 'button') &&
                          item.tag && <span className="tag">{item.tag}</span>
                        }
                        {item.tagMuted && (
                          <span className="tag-muted">{item.tagMuted}</span>
                        )}
                        {item?.type === 'menu' && (
                          <span className="forward-symbol text-muted" />
                        )}
                        {item?.type === 'switch' && (
                          <div className="form-check form-switch">
                            <label
                              className="form-check-label"
                              role="button"
                              htmlFor={`flexSwitchCheckDefault${idx}`}
                              style={{
                                position: 'absolute',
                                width: '100%',
                                left: 0,
                                top: 0,
                                height: '100%',
                                zIndex: 1,
                              }}
                            ></label>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              name={idx}
                              value={idx}
                              checked={item.value}
                              id={`flexSwitchCheckDefault${idx}`}
                              onChange={(e) => onClick(id, e.target.checked)}
                            ></input>
                          </div>
                        )}

                        {item?.type === 'static' && item.value && (
                          <span className="">{item.value}</span>
                        )}

                        {false && !item.hideSubmenu && (
                          <span className="forward-symbol text-muted" />
                        )}
                      </div>
                    </div>
                  </div>
                </li>
              )
            );
          })}
      </ul>
    </>
  );
}

function TodoListDone({
  header = '',
  caption = 'Done',
  bgColor = 'var(--theme-primary)',
}) {
  return (
    <>
      {header && (
        <div
          className="text-muted text-uppercase"
          style={{ padding: '2px 16px' }}
        >
          <small>{header}</small>
        </div>
      )}
      <ul
        className="list-group list-group-flush"
        style={{
          opacity: 0.8,
          '--bs-list-group-item-padding-y': '30px',
          '--bs-list-group-bg': `${bgColor}`,
          '--bs-list-group-color': 'var(--theme-default)',
        }}
      >
        <li
          style={{
            border: 'none',
            padding: '30px',
          }}
          className="list-group-item "
        >
          <div className="row g-2">
            <div className="col text-center text-uppercase-">
              <div style={{ fontWeight: 400, lineHeight: '14px' }}>
                {caption}
              </div>
            </div>
          </div>
        </li>
      </ul>
    </>
  );
}

function TodoList({ items, header = '', sort = true }) {
  /*  items = [{ id, name }]   */

  const sortedItems =
    items && sort
      ? items.sort((a, b) =>
          a.isDone && !b.isDone ? -1 : !a.isDone && b.isDone ? 1 : 0
        )
      : items;
  return (
    <>
      {header && items && (
        <div
          className="text-muted text-uppercase"
          style={{ padding: '2px 16px' }}
        >
          <small>{header}</small>
        </div>
      )}
      <ul
        className="list-group list-group-flush"
        style={{
          '--bs-list-group-item-padding-y': '30px',
        }}
      >
        {sortedItems &&
          sortedItems.map((item, idx) => {
            return (
              <li
                style={{
                  border: 'none',
                  paddingBottom: `${
                    idx === sortedItems.length - 1 ? 'default' : '6px'
                  }`,
                  paddingTop: `${idx === 0 ? 'default' : '6px'}`,
                }}
                key={idx}
                className="list-group-item "
              >
                <div className="row g-2">
                  <div
                    className="col-auto"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <img
                      src={item.isDone ? '/img/success.svg' : '/img/hole.svg'}
                      alt=""
                      style={{
                        width: '100%',
                        maxWidth: '18px',
                      }}
                    />
                  </div>
                  <div className="col text-muted">
                    <div style={{ lineHeight: '14px' }}>
                      <small> {item.name}</small>
                    </div>
                    <div>
                      <small className="text-muted">{item.descr}</small>
                    </div>
                  </div>
                </div>
              </li>
            );
          })}
      </ul>
    </>
  );
}

function ProgressBar({
  show = true,
  max = 35,
  position = 0,
  barsCount = 25,
  top = 46.8,
  onClick = () => {},
  //...props
}) {
  const [bars, setBars] = useState([]);

  useEffect(() => {
    setBars(Array(barsCount).fill(0));
  }, [position]);

  const padding = 15;
  const progressWidth = 180;
  const barSize = Math.ceil(
    (progressWidth - padding * 2 - barsCount * 2) / barsCount
  );
  const height = barSize + padding * 2;
  const currIdx = Math.floor((position / max) * barsCount);
  const opacity = show && currIdx ? 1 : 0;
  return (
    <>
      <div
        className="blur-filter"
        style={{
          position: 'absolute',
          height: `${height}px`,
          zIndex: 10,
          borderRadius: '6px',
          padding: `0 ${padding}px`,
          left: '50%',
          top: '100px',
          transform: 'translateX(-50%)',
          backgroundColor: 'var(--theme-overlay-transparent)',
          flexFlow: 'nowrap',
          display: 'flex',
          opacity: opacity,
          transition: 'opacity 1s',
        }}
        onClick={() => (currIdx ? onClick() : null)}
      >
        <small
          style={{
            alignSelf: 'center',
            textWrap: 'nowrap',
          }}
          className="text-muted text-uppercase"
        >
          {t('Bonus')}
        </small>
        <div
          style={{
            margin: `${padding}px`,
            flexFlow: 'nowrap',
            display: 'flex',
          }}
        >
          {bars.map((_, id) => (
            <div
              key={id}
              style={{
                height: `${barSize}px`,
                width: `${barSize}px`,
                boxShadow: id < currIdx ? '0 0 10px var(--theme-primary)' : '',
                backgroundColor:
                  id < currIdx
                    ? 'var(--theme-primary-accent)'
                    : 'var(--theme-muted)',
                position: 'relative',
                margin: '1px',
                float: 'left',
              }}
            />
          ))}
        </div>
        <small
          style={{
            alignSelf: 'center',
            textWrap: 'nowrap',
            flexWrap: 'nowrap',
            display: 'flex',
          }}
        >
          {position >= max ? (
            <>
              <span
                className="glow-small text-primary-accent text-uppercase"
                style={{ minWidth: '15px' }}
              >
                {t('Claim')}
              </span>
            </>
          ) : (
            <>
              <span className="text-primary" style={{ minWidth: '15px' }}>
                {position.toFixed(0)}
              </span>
              <span className="text-muted" style={{ margin: '0 5px' }}>
                │
              </span>
              <span className="text-muted">{Math.max(max).toFixed(0)}</span>
            </>
          )}
        </small>
      </div>
    </>
  );
}

function SvgStyles({}) {
  const offsetFromMetals = 0.309287;
  const offsetToMetals = 0.757667;

  const offsetFrom = 0;
  const offsetTo = 1;

  return (
    <svg style={{ position: 'absolute' }} width="0" height="0">
      <defs>
        <radialGradient
          id="radial_shadow"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(24.249 56.4961) rotate(-23.471) scale(41.4276 46.8278)"
        >
          <stop stopColor="white" stopOpacity="0" />
          <stop offset="0.551" stopOpacity="0.3" />
          <stop offset="0.696" stopOpacity="0.4" />
        </radialGradient>

        <linearGradient
          id="gradient-primary"
          x1="0"
          y1="40"
          x2="40"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            offset={offsetFromMetals}
            stopColor={`var(--theme-primary-dark)`}
            stopOpacity={0.95}
          />
          <stop
            offset={offsetToMetals}
            stopColor={'var(--theme-primary)'}
            stopOpacity={0.95}
          />
        </linearGradient>

        <linearGradient
          id="gradient-bronze"
          x1="0"
          y1="40"
          x2="40"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            offset={offsetFromMetals}
            stopColor="var(--theme-bronze-dark)"
          />
          <stop offset={offsetToMetals} stopColor="var(--theme-bronze-light)" />
        </linearGradient>

        <linearGradient
          id="gradient-silver"
          x1="0"
          y1="40"
          x2="40"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            offset={offsetFromMetals}
            stopColor={'var(--theme-silver-dark)'}
          />
          <stop
            offset={offsetToMetals}
            stopColor={'var(--theme-silver-light)'}
          />
        </linearGradient>

        <linearGradient
          id="gradient-gold"
          x1="0"
          y1="40"
          x2="40"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={offsetFromMetals} stopColor="var(--theme-gold-dark)" />
          <stop offset={offsetToMetals} stopColor="var(--theme-gold-light)" />
        </linearGradient>

        <linearGradient
          id="gradient-ruby"
          x1="0"
          y1="40"
          x2="40"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={offsetFrom} stopColor={'var(--theme-ruby-pink-dark)'} />
          <stop offset={offsetTo} stopColor={'var(--theme-ruby-pink-light)'} />
        </linearGradient>
        <linearGradient
          id="gradient-amethyst"
          x1="0"
          y1="40"
          x2="40"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            offset={offsetFrom}
            stopColor={'var(--theme-amethyst-velvet-dark)'}
          />
          <stop offset={offsetTo} stopColor={'var(--theme-amethyst-velvet)'} />
        </linearGradient>
        <linearGradient
          id="gradient-crystal"
          x1="0"
          y1="40"
          x2="40"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={offsetFrom} stopColor={'var(--theme-crystal-dark)'} />
          <stop offset={offsetTo} stopColor={'var(--theme-crystal-light)'} />
        </linearGradient>

        <linearGradient
          id="gradient-obsidian"
          x1="0"
          y1="40"
          x2="40"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            offset={offsetFromMetals}
            stopColor={'var(--theme-obsidian-dark)'}
          />
          <stop
            offset={offsetToMetals}
            stopColor={'var(--theme-obsidian-light)'}
          />
        </linearGradient>
      </defs>
    </svg>
  );
}

function SuperBox({ box, boxSizePx, ...props }) {
  const multiplier = box.multiplier;
  const styleId =
    multiplier >= 40
      ? 'gradient-obsidian'
      : multiplier >= 20
      ? 'gradient-amethyst'
      : multiplier >= 10
      ? 'gradient-ruby'
      : multiplier >= 8
      ? 'gradient-crystal'
      : multiplier >= 6
      ? 'gradient-gold'
      : multiplier >= 5
      ? 'gradient-silver'
      : multiplier >= 4
      ? 'gradient-bronze'
      : 'gradient-primary';

  /*   return (
    <svg className="svg-box" {...props} fill="none">
      <rect
        x="0"
        y="0"
        width="100%"
        height="100%"
        fill={`url(#${styleId})`}
        stroke="var(--theme-muted)"
      />
    </svg>
  );
 */
  return (
    props.fillOpacity > 0 && (
      <svg
        className="svg-box"
        x={props.x}
        y={props.y}
        width={props.width}
        height={props.height}
        viewBox="0 0 40 40"
        fill="none"
        preserveAspectRatio="none"
      >
        <rect
          x="0"
          width="100%"
          height="100%"
          rx="2"
          fill={`url(#${styleId})`}
        />
        <rect
          x="0"
          y="40"
          width="100%"
          height="100%"
          rx="2"
          transform="rotate(-90 0 40)"
          fill="url(#radial_shadow)"
          style={{ mixBlendMode: 'hard-light' }}
        />
        <path
          d="M0.745117 2C0.745117 0.895431 1.64055 0 2.74512 0H26.7451L25.29 1.46915C24.2849 2.48391 23.1324 3.34123 21.8716 4.01217V4.01217C18.668 5.71683 16.2387 8.58323 15.0823 12.0229L11.8964 21.4987C9.89893 27.4399 5.97416 32.5438 0.745117 36V36V2Z"
          fill="white"
          fillOpacity="0.2"
          style={{ mixBlendMode: 'hard-light' }}
        />
      </svg>
    )
  );
}

function BtnIcon({ src }) {
  return (
    <div className="btn-icon">
      <img src={src} />
    </div>
  );
}

function SupportLink() {
  const { openWebBrowser } = useNativeAppContext();
  const showEmailLink = !isTelegram() && !isNativeApp();

  return (
    <>
      {!showEmailLink && (
        <small
          className="text-primary btn-link"
          onClick={() => {
            openWebBrowser('https://boxo.on.spiceworks.com');
          }}
        >
          <span>{t('Submit a ticket')}</span>
        </small>
      )}
      {showEmailLink && (
        <small className="text-primary btn-link">
          <a
            href="https://boxo.on.spiceworks.com"
            target="_blank"
            rel="external nofollow noopener"
          >
            {t('Submit a ticket')}
          </a>
        </small>
      )}
      <small className="text-muted">
        <span>. {t('Email us at {{address}}', { address: '' })}</span>
      </small>
      {!showEmailLink && (
        <small className="text-muted">
          <span>support@boxo.trade</span>
        </small>
      )}
      {showEmailLink && (
        <small className="text-primary btn-link">
          <a href="mailto:support@boxo.trade">support@boxo.trade</a>
        </small>
      )}
    </>
  );
}

export {
  ProgressBar,
  DebugControl,
  BetControl,
  SelectionList,
  TodoList,
  TodoListDone,
  BonusItem,
  SoundControl,
  BlurPanel,
  BackgoundLight,
  SvgStyles,
  SuperBox,
  AnimatedHand,
  BtnIcon,
  SupportLink,
};

import { useEffect, useRef, useState } from 'react';
import { useInterval } from '../shared/hooks/use-interval';

export default function useEmulatorPrice(callback) {
  //console.log('useEmulatorPrice--- 1');

  const data = require('./matches.json');
  const callbackRef = useRef();

  const initIdx = 40; //0
  const [lastJsonMessage, setLastJsonMessage] = useState(null);
  const [idx, setIdx] = useState(initIdx);
  const [rows, setRows] = useState(0);

  useEffect(() => {
    setRows(data?.matches.length);
    callbackRef.current = callback;
  }, [callback]);

  useInterval(() => {
    const event = {
      ...data?.matches[idx]?.data,
      price: { ...data?.matches[idx]?.data.price, timestamp: Date.now() },
    };
    console.log('Emulator step: ', idx, ' out of ', rows);
    setLastJsonMessage(event);
    if (idx >= rows - 1) setIdx(initIdx);
    else setIdx(idx + 1);
  }, 500);

  useEffect(() => {
    if (lastJsonMessage) {
      callbackRef.current(lastJsonMessage);
    }
  }, [lastJsonMessage]);
}

import { useEffect, useState } from 'react';
import { t } from 'i18next';
import { QRCodeSVG } from 'qrcode.react';
import { useCopyToClipboard } from '../shared/hooks/use-hooks';
import { SelectionList } from '../components/terminal';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { ModalLayout } from '../shared/layout';
import { useLoggerContext } from '../shared/hooks/use-logger';

const DepositDialog = ({ assets, logger }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [step, setStep] = useState(1);
  const [selectedAsset, setSelectedAsset] = useState({
    name: '',
    descr: '',
    networks: [],
  });

  const [selectedNetwork, setSelectedNetwork] = useState({
    name: '',
    descr: '',
    depositAddress: '',
  });

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <SelectAssetForm
              assets={assets}
              onSelect={(asset) => {
                setSelectedAsset(asset);
                setStep(step + 1);
                navigate('./network', {
                  replace: true,
                  state: location.pathname,
                });
              }}
            />
          }
        />

        <Route
          path="/network"
          element={
            <SelectNetworkForm
              networks={selectedAsset.networks}
              onSelect={(network) => {
                setSelectedNetwork(network);
                setStep(step + 1);
                navigate('./qr', {
                  replace: true,
                  state: location.pathname,
                });
              }}
            />
          }
        />

        <Route
          path="/qr"
          element={
            <QrForm
              selectedNetwork={selectedNetwork}
              selectedAsset={selectedAsset}
            />
          }
        />
      </Routes>
    </>
  );
};

function SelectAssetForm({ onSelect, assets }) {
  const navigate = useNavigate();
  return (
    <ModalLayout onClose={() => navigate('/app', { replace: true })}>
      <div className="modal-header">
        <h1 className="text-default text-center">{t('Deposit crypto')}</h1>
      </div>
      <div className="modal-body">
        <SelectionList
          header={t('Select asset')}
          items={assets.map((asset) => ({ ...asset, type: 'menu' }))}
          onClick={(idx) => {
            onSelect && onSelect(assets[idx]);
          }}
        />
      </div>
    </ModalLayout>
  );
}

function SelectNetworkForm({ onSelect, networks }) {
  const navigate = useNavigate();
  return (
    <ModalLayout onClose={() => navigate('/app', { replace: true })}>
      <div className="modal-header">
        <h1 className="text-default text-center">{t('Select a network')}</h1>
      </div>
      <div className="modal-body">
        <br />
        <SelectionList
          items={networks.map((network) => ({
            ...network,
            type: 'menu',
          }))}
          onClick={(idx) => {
            onSelect(networks[idx]);
          }}
        />
      </div>

      <div className="modal-footer no-mobile-keyboard"></div>
    </ModalLayout>
  );
}

function QrForm({ selectedAsset, selectedNetwork }) {
  const logger = useLoggerContext();
  const [_, copyToClipboard] = useCopyToClipboard();
  const copyLabelText = t('Copy address');
  const [copyLabel, setCopyLabel] = useState(copyLabelText);

  useEffect(() => {
    if (copyLabel !== copyLabelText) {
      setTimeout(() => {
        setCopyLabel(copyLabelText);
      }, 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [copyLabel]);
  const navigate = useNavigate();

  return (
    <ModalLayout onClose={() => navigate('/app', { replace: true })}>
      <div className="modal-header">
        <h1 className="text-default text-center">
          {t('Deposit')} {selectedAsset.name}
        </h1>
        <div className="text-muted text-center">{selectedAsset.descr}</div>
      </div>
      <div className="modal-body">
        <div className="qr-code">
          <div>
            <QRCodeSVG size="100%" value={selectedNetwork.depositAddress} />
          </div>
          <div
            className="text-center"
            style={{
              overflowWrap: 'anywhere',
              width: '100%',
              fontSize: '12px',
              fontWeight: '600',
              paddingTop: '10px',
              color: 'black',
              lineHight: '12px',
            }}
          >
            {selectedNetwork.depositAddress}
          </div>
        </div>

        <div className="mt-2 text-muted text-center">
          {selectedNetwork.tokenStandard} {t('address for')}{' '}
          {selectedNetwork.descr} ({selectedNetwork.name})
        </div>
      </div>

      <div className="modal-footer no-mobile-keyboard justify-content-center">
        <div className="text-center text-muted">
          {t(`Minimal deposit is`)} {selectedNetwork.minDeposit}{' '}
          {selectedAsset.name}
        </div>

        <div
          className="btn btn-primary"
          role="button"
          onClick={() => {
            if (selectedNetwork.depositAddress) {
              copyToClipboard(selectedNetwork.depositAddress);
              setCopyLabel(t('Copied!'));

              logger &&
                logger.action('deposit', {
                  step: 100,
                  asset: selectedAsset.name,
                  network: selectedNetwork.name,
                });
            }
          }}
        >
          {copyLabel}
        </div>
      </div>
    </ModalLayout>
  );
}

export { DepositDialog };

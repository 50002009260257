import { Helmet } from 'react-helmet';
import { useAdsenseContext } from './hooks/use-adsense';
import { useMonetagContext } from './hooks/use-monetag';

export default function Header({ title, description, backgroundColor }) {
  const themeColor = backgroundColor ? backgroundColor : '#000000';
  const { getAdsenseHeaderScript } = useAdsenseContext();
  const { getMonetagHeaderScript } = useMonetagContext();

  return (
    <Helmet>
      <title>{title}</title>
      <meta charSet="UTF-8" />
      <meta name="description" content={description} />
      <meta httpEquiv="X-UA-Compatible" content="chrome=1" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta
        name="apple-mobile-web-app-status-bar-style"
        content="black-translucent"
      />
      <meta
        name="viewport"
        content="viewport-fit=cover, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
      />
      <meta
        name="theme-color"
        content={themeColor}
        media="(prefers-color-scheme: dark)"
      />
      <meta
        name="theme-color"
        content={themeColor}
        media="(prefers-color-scheme: light)"
      />
      <meta name="theme-color" content={themeColor} />
      {getAdsenseHeaderScript()}
      {getMonetagHeaderScript()}
    </Helmet>
  );
}
